import gql from 'graphql-tag'

const GET_LANE_COMPTAGE_AGGREGATE = gql`
  query getComptage($startDate: date , $endDate: date, $lane_id: uuid) {
      comptage_aggregate(where: {_and: [
        {day: {_gte: $startDate}}
        {day: {_lte: $endDate}}
        {lane_id: {_eq: $lane_id}}
      ]},order_by: {start_time: asc}) {
        bicycle_count
        bus_count
        car_count
        motorbike_count
        person_count
        truck_count
        created_at
        day
        day_time
        end_time
      }
    }
`
const GET_LANES = gql`
query getLanes {
  lane {
    id
    name
    color
    place_id
  }
}
`
export {
  GET_LANE_COMPTAGE_AGGREGATE,
  GET_LANES
}