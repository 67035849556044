<template>
  <section class="datas">
    <h1 >Exemple de données de traffic</h1>
    <p>Les données ci dessous sont capturées en direct sur une caméra du CD06, située entre Antibes et Nice</p>
    <h2> <span> Avertissement</span> </h2>
    <p><b>Mise en garde: </b> ces données sont encore <u>expérimentales</u> et peuvent fortement dévier de la réalité! Elles sont fournies à titre informatif.</p>
    <section class="filters-top">
      <div class="left">
        <p> <b>Choix de la voie de trafic: </b></p>
        <select @change="setDatas" v-model="lane" v-if="res_lanes && res_lanes.lane" name="source" id="source">
          <option disabled selected :value="null">Source</option>
          <option v-for="lane in res_lanes.lane" :key="lane.id" :value="lane.id">{{lane.name}}</option>
        </select>
        <p> <b>Choix de la date (sur une journée ou une plage de temps): </b></p>
        <div class="bot">
          <div class="input-container">
            <label for="startDate">Date de début</label>
            <input  v-model="startDate" type="date" id="startDate" name="startDate">
          </div>
          <div class="input-container">
            <label for="endDate">Date de fin</label>
            <input placeholder="Date de fin" v-model="endDate" type="date" id="endDate" name="endDate">
          </div>
          <div class="validate-button" @click="setDatas">Mettre à jour</div>
        </div>
      </div>
      <div class="map">
        <img src="@/assets/marina_sud_sens.png" alt="">
        <a href="https://www.openstreetmap.org/search?whereami=1&query=43.63039%2C7.13498#map=19/43.63039/7.13498" target="_blank">Localisation</a>
      </div>
    </section>
    <section class="graphs">
      <p> <b>Graphiques: </b></p>
      <div class="graph graph-average">
        <p>Moyenne par heure sur les jours selctionnés</p>
        <Loader :key="averageLoading" class="graph-loader" v-if="averageLoading"></Loader>
        <apexchart width="800" type="bar" :options="optionsAverage" :series="serieAverage"></apexchart>
      </div>
      <div class="graph graph-full">
        <p>Ensemble des données sur les jours sélectionnés</p>
        <Loader :key="fullLoading" class="graph-loader" v-if="fullLoading"></Loader>
        <apexchart width="800" type="bar" :options="optionsFull" :series="serieFull"></apexchart>
      </div>
    </section>
  </section>
</template>
<script>
import { useQuery } from '@vue/apollo-composable'
// import gql from 'graphql-tag'
import { apolloClient } from '@/apolloClient'
import { GET_LANE_COMPTAGE_AGGREGATE, GET_LANES } from '@/gql/datas'
import Loader from '@/components/Loader'
export default {
  components: {
    Loader,
  },
  async mounted () {
    await this.setDatas()
  },
  setup () {
    const { result: res_lanes } = useQuery(GET_LANES)

    return { res_lanes }
  },
  data: function() {
    return {
      optionsAverage: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            show: true
          },
          zoom: {
            enabled: true
          }
        },
        xaxis: {
          categories: ["00", "01", "02", "03", "04", "05","06", "07", "08", "09", "10", "11","12", "13", "14", "15", "16", "17","18", "19", "20", "21", "22", "23"]
        },
        colors:['#F44336', '#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800']
      },
      optionsFull: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            show: true
          },
          zoom: {
            enabled: true
          },
        } ,       
          colors:['#F44336', '#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800']
      },
      startDate: "2021-07-24",
      endDate: "2021-07-25",
      lane: "d2119751-f857-451f-8dfc-96610220330d",
      comptage_aggregate: [],
      hours: ["00", "01", "02", "03", "04", "05","06", "07", "08", "09", "10", "11","12", "13", "14", "15", "16", "17","18", "19", "20", "21", "22", "23"],
      daysHour: {},
      averageLoading: false,
      fullLoading: false,
      serieAverage: this.defaultSerie(),
      serieFull: this.defaultSerie()
    }
  },
  methods: {
    formatDate(date) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;

      return [year, month, day].join('-');
    },
    async setDatas() {
      this.averageLoading = true
      this.fullLoading = true
        let {data: { comptage_aggregate } } = await apolloClient.query({
        query: GET_LANE_COMPTAGE_AGGREGATE, 
        variables: {
          startDate: this.startDate,
          endDate: this.endDate, 
          lane_id: this.lane
        }
      }) 
      this.comptage_aggregate = comptage_aggregate
      setTimeout(() => {
        this.setSeries()
      });
    },
    setSeries() {
      let daysHour = {}
      this.comptage_aggregate.forEach(comptage => {
        let day = comptage.day
        let hour = comptage.day_time.split(':')[0]
        if(daysHour[day]) {
          if(daysHour[day][hour]) {
            let currentValue = daysHour[day][hour]
            let newValue = {
              car_count: currentValue.car_count + comptage.car_count, 
              truck_count: currentValue.truck_count + comptage.truck_count, 
              bus_count: currentValue.bus_count + comptage.bus_count, 
              motorbike_count: currentValue.motorbike_count + comptage.motorbike_count, 
              bicycle_count: currentValue.bicycle_count + comptage.bicycle_count, 
              person_count: currentValue.person_count + comptage.person_count
            }
            daysHour[day][hour] = newValue
          }
          else daysHour[day][hour] = comptage
        }
        else daysHour[day] = {[hour]: comptage}
      })
      this.daysHour = daysHour
      this.optionsFull.xaxis = {categories: Object.keys(daysHour)}
      
      let dataIndex = {
        car: 0,
        truck: 1,
        motorbike: 2,
        bus: 3,
        person: 4,
        bicycle: 5
      } 
      let serieAverage = this.defaultSerie()
      let serieFull = this.defaultSerie()
      let numDays = Object.keys(this.daysHour).length
      for(let day in this.daysHour) {
        this.hours.forEach(hour => {
          if(this.daysHour[day][hour]) {
            serieFull[dataIndex.car].data.push(this.daysHour[day][hour].car_count)
            serieFull[dataIndex.truck].data.push(this.daysHour[day][hour].truck_count)
            serieFull[dataIndex.motorbike].data.push(this.daysHour[day][hour].motorbike_count)
            serieFull[dataIndex.bus].data.push(this.daysHour[day][hour].bus_count)
            serieFull[dataIndex.person].data.push(this.daysHour[day][hour].person_count)
            serieFull[dataIndex.bicycle].data.push(this.daysHour[day][hour].bicycle_count)
            

            serieAverage[dataIndex.car].data[parseInt(hour)] = (serieAverage[dataIndex.car].data[parseInt(hour)] || 0) + Math.ceil(this.daysHour[day][hour].car_count / numDays)

            serieAverage[dataIndex.truck].data[parseInt(hour)] = (serieAverage[dataIndex.truck].data[parseInt(hour)] || 0) + Math.ceil(this.daysHour[day][hour].truck_count / numDays)

            serieAverage[dataIndex.motorbike].data[parseInt(hour)] = (serieAverage[dataIndex.motorbike].data[parseInt(hour)] || 0) + Math.ceil(this.daysHour[day][hour].motorbike_count / numDays)

            serieAverage[dataIndex.bus].data[parseInt(hour)] = (serieAverage[dataIndex.bus].data[parseInt(hour)] || 0) + Math.ceil(this.daysHour[day][hour].bus_count / numDays)

            serieAverage[dataIndex.person].data[parseInt(hour)] = (serieAverage[dataIndex.person].data[parseInt(hour)] || 0) + Math.ceil(this.daysHour[day][hour].person_count / numDays)

            serieAverage[dataIndex.bicycle].data[parseInt(hour)] = (serieAverage[dataIndex.bicycle].data[parseInt(hour)] || 0) + Math.ceil(this.daysHour[day][hour].bicycle_count / numDays)

          } else {
            serieAverage[dataIndex.car].data[parseInt(hour)] = serieAverage[dataIndex.car].data[parseInt(hour)] || 0
            serieAverage[dataIndex.truck].data[parseInt(hour)] = serieAverage[dataIndex.truck].data[parseInt(hour)] || 0
            serieAverage[dataIndex.motorbike].data[parseInt(hour)] = serieAverage[dataIndex.motorbike].data[parseInt(hour)] || 0
            serieAverage[dataIndex.bus].data[parseInt(hour)] = serieAverage[dataIndex.bus].data[parseInt(hour)] || 0
            serieAverage[dataIndex.person].data[parseInt(hour)] = serieAverage[dataIndex.person].data[parseInt(hour)] || 0
            serieAverage[dataIndex.bicycle].data[parseInt(hour)] = serieAverage[dataIndex.bicycle].data[parseInt(hour)] || 0
            
            serieFull[dataIndex.car].data.push(0)
            serieFull[dataIndex.truck].data.push(0)
            serieFull[dataIndex.motorbike].data.push(0)
            serieFull[dataIndex.bus].data.push(0)
            serieFull[dataIndex.person].data.push(0)
            serieFull[dataIndex.bicycle].data.push(0)
          }
        })
      }
      this.serieFull = serieFull
      this.serieAverage = serieAverage
      // return serie
      this.averageLoading = false
      this.fullLoading = false
    },
    defaultSerie() {
      return [{
          name: 'Voiture',
          data: []
        }, {
          name: 'Camion',
          data: []
        }, {
          name: 'Moto',
          data: []
        },
        {
          name: 'Bus',
          data: []
        },
        {
          name: 'Personne',
          data: []
        },
        {
          name: 'Vélo',
          data: []
        }]
    }
  },
}
</script>

<style lang="scss">
.datas {
  .title {
    font-size: 30px;
    text-transform: uppercase;
    margin: 20px;
    text-align: center;
    span {
      font-size: 20px;
      text-transform: uppercase;
    }
  }
  h1, h2, p, select, label {
    margin-bottom: 2em;
    margin: 0.8em 0em;
  }
  select, input {
    border: 1px solid grey;
    border-radius: 0.25em;
    padding: 0.25em 0.5em;
    cursor: pointer;
    line-height: 1.1;
    background-color: #fff;
    background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
  }
  p {
    width: 50%;
  }
  .filters-top {
    margin-bottom: 2em;
    display: flex;
    justify-content: space-between;
    .left {
      width: 70%;
      >p {
        margin-bottom: 0em;
        padding-bottom: 0px;
      }

      .bot {
        width: 60%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .input-container {
          display: flex;
          flex-direction: column;
        }
        .validate-button  {
          background: rgb(255, 224, 0);
          padding: 0.5em;
          cursor: pointer;
        }
      }
    }
    .map {
      width: 30%;
      // img {
      //   width: 500px;
      // }
    }
  }

  .graphs {
    display: flex;
    justify-content: center;
    flex-direction: column;
    .graph {
      width: 800px;
      position: relative;
      .graph-loader {
        position: absolute;
        z-index: 2;
        top: 50%;
        left: 50%;
      }
    }
    .graph-average {
      margin-bottom: 4em;
    }
  }
  
}
</style>